<template>
    <final-page>
        <h1>
            תודה שמילאת את השאלון!
        </h1>
        <div :style='{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }'>
            אנחנו מקווים שביחד נמצא את הדרך להבנה טובה יותר וטיפול טוב יותר.
        </div>
    </final-page>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import FinalPage from '@/Study/Pages/Final.vue';

export default defineComponent({
    components: { FinalPage }
});
</script>